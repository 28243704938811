<template>
  <b-card>
    <div slot="header">
      <strong>Opções do sistema</strong>
    </div>
    <form v-on:submit.prevent="salvaGrupo">
      <div class="row">

        <div class="col-lg-4">
          <div class="form-group">
              <label name="mnemonico"><strong>Nome Fantasia</strong></label>
              <input type="text" class="form-control" readonly v-model="grupo.grp_mnemonico" id="mnemonico" required>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="form-group">
              <label name="descricao"><strong>Empresa</strong></label>
              <input type="text" class="form-control" readonly v-model="grupo.grp_descricao" id="descricao" required>
          </div>
        </div>

      </div>
      <div class="row">

        <!--<div class="col-lg-3">
            <label name="dataCriacao"><strong>Limpar base</strong></label><br>
          <b-button variant="warning" style="color:white;" @click="resetarTudo()">Resetar minha conta</b-button>
        </div>-->

        <div class="col-lg-6">
          <div class="form-group">
              <label name="dataCriacao"><strong>Moeda base</strong></label>
              <p>REAL (R$)</p>
              <!--<select class="form-control" v-model="grupo.grp_moeda">
                <option value="R$">Real (R$)</option>
                <option value="US$">Dólar (US$)</option>
                <option value="€">Euro (€)</option>
              </select>-->
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="dataCriacao"><strong>Meu Plano</strong></label>
              <p>{{ grupo.grp_registros ? 'Até ' + grupo.grp_registros + ' produtos' : 'Ilimitado' }}</p>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="dataCriacao"><strong>Validade de acesso</strong></label>
              <p>{{ grupo.grp_data_validade ? grupo.grp_data_validade : 'Indeterminado' }}</p>
          </div>
        </div>

      </div>
<hr>
      <div class="row">
        <div class="col-lg-12">
          <h5>Cotação de hoje<span v-html="cotData"></span></h5>
        </div>
        <div class="col-lg-12">
          <div id="cotacoes" v-html="cot"></div>
        </div>
      </div>

      <!--<button type="submit" disabled class="btn btn-primary">Salvar opções</button>-->

      <small>OBS: As cotações são atualizadas uma vez ao dia de maneira automática pelo Gera Preço.</small><br>
      <button type="button" class="btn" @click="cotacoesAtualizar()">Atualizar cotações</button>
    </form>
  </b-card>
</template>

<script>
export default {
  data () {
    return {
      grupo: {
        grp_mnemonico: '',
        grp_descricao: '',
        grp_ativo: 0,
        gtipo_ID: 2,
        grp_coord_areas: 1,
        grp_ID: 0,
        grp_moeda: 'R$'
      },
      cotData: '',
      cot: 'Carregando...'
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_IMPOSTOS'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.grupo.grp_ID = this.$session.get('grupo')
        this.carregarGrupo()
        this.cotacoes()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    carregarGrupo: function () {
      this.campos('gp_Grupo', 'grp_ID = ' + this.grupo.grp_ID).then(
        (response) => {
          this.grupo = response
          console.log(this.grupo)
        },
        (response) => {}
      )
    },
    cotacoes: function () {
      this.$http.get(this.URLApp + 'tabelas/updateCotacao')
      this.$http.get(this.URLApp + 'files/cotacao.txt?' + new Date().getTime()).then(
        (p) => {
          var c = p.body
          this.cotData = ''
          this.cot = '<ul>'
          this.cot += '<li><strong>Dólar Americano ($ - USD)</strong><br>R$ ' + c['results']['currencies']['USD']['buy'] + '</li>'
          this.cot += '<li><strong>Euro (€ - EUR)</strong><br>R$ ' + c['results']['currencies']['EUR']['buy'] + '</li>'
          this.cot += '<li><strong>Libra Esterlina (£ - GBP)</strong><br>R$ ' + c['results']['currencies']['GBP']['buy'] + '</li>'
          this.cot += '<li><strong>Yen Japonês (¥ - JPY)</strong><br>R$ ' + c['results']['currencies']['JPY']['buy'] + '</li>'
          this.cot += '<li><strong>Renminbi Chinês (元 - CNY)</strong><br>R$ ' + c['results']['currencies']['CNY']['buy'] + '</li>'
          this.cot += '<li><strong>Dólar Canadense ($ - CAD)</strong><br>R$ ' + c['results']['currencies']['CAD']['buy'] + '</li>'
        },
        (p) => {
          this.cotData = '(Falha ao buscar tabela de cotações)'
          this.cot = JSON.stringify(p)
        }
      )
    },
    cotacoesAtualizar: function () {
      this.$http.get(this.URLApp + 'tabelas/updateCotacao/force').then(
        (a) => { document.location.reload() },
        (a) => { alert('Falha ao atualizar...') }
      )
    },
    salvaGrupo: function () {
      this.atualizar('gp_Grupo', this.grupo, this.grupo.grp_ID).then(
        (response) => {
          this.$swal.fire({
            title: 'Alterações salvas',
            text: 'É necessário que você saia e entre novamente no sistema para ver as alterações.',
            icon: 'success'
          })
          this.$router.push('/dashboard')
        },
        (response) => {}
      )
    },
    resetarTudo: function () {
      this.$swal.fire({
        title: 'Tem certeza disso?',
        text: 'Esta ação limpa TODOS os dados dessa empresa (exceto contas de usuários auxiliares). Não há maneiras de desfazer essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK! Estou ciente disso.',
        cancelButtomText: 'Cancelar ação',
      }).then((result) => {
        if (result.value) {
          var cond = 'grp_ID = ' + this.grupo.grp_ID
          var prom = []
          prom.push(this.removerAdm('gp_Metadados', cond))
          prom.push(this.removerAdm('pro_Encargo_Item', cond))
          prom.push(this.removerAdm('pro_Encargo', cond))
          prom.push(this.removerAdm('pro_Produto_Maodeobra', cond))
          prom.push(this.removerAdm('pro_Produto_Material', cond))
          prom.push(this.removerAdm('pro_Material', cond))
          prom.push(this.removerAdm('pro_Material_Unidade', cond))
          prom.push(this.removerAdm('pro_Maodeobra', cond))
          prom.push(this.removerAdm('pro_Produto', cond))
          prom.push(this.removerAdm('pro_Produto_Linha', cond))
          Promise.all(prom).then(
            (a) => {
              this.$swal.fire(
                'Dados limpos.',
                'Todos os dados foram removidos.',
                'success'
              )
            },
            (a) => {}
          )
        }
      })
    }
  }
}
</script>